import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import '../components/css/Footer.css';

function Footer() {
  return (
    <footer>
      <Container>
        <div className="d-flex justify-content-between flex-column flex-md-row flex-wrap pt-5 pb-4">
          <motion.div
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <img
            style={{width:'10%', background:'transparent'}}
              src={require("../images/dsqlogo.jpeg")}
              alt="logo"
              className="mb-3"
            />
          
            
          </motion.div>
          <span className="d-block d-md-none"></span>
          <motion.div
            initial={{ x: 200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            className="d-flex"
          >
            <div className="me-5">
              <h6 className="gray-100 text-uppercase mb-2 fw-normal">
                Company
              </h6>
              <ul className="p-0">
              
                <Link to="/contact"><li>Contact</li></Link>
           
           
                
              </ul>
            </div>
            <div>
              <h6 className="gray-100 text-uppercase mb-2 fw-normal">
                Socials
              </h6>
              <ul className="p-0 list">
                <li><a style={{color:'white'}}href="https://x.com/DropSquadProd"><XIcon/>
               </a></li>
                <li><a style={{color:'white'}}href="https://www.youtube.com/@DropSquadProductions"><YouTubeIcon/></a></li>
                <li><a style={{color:'white'}}href="https://www.facebook.com/people/Drop-Squad-Productions/61560334190075/"><FacebookIcon/></a></li>
              </ul>
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="d-flex justify-content-between flex-column flex-md-row flex-wrap gray-100 pt-3"
        >
          <p>© 2024 Drop Squad Productions. All rights reserved</p>
         
        </motion.div>
      </Container>
    </footer>
  );
}

export default Footer;
