import { Button, Container } from "react-bootstrap";
import AnimationTitles from "../components/functions/AnimationTitles";
import { useState } from 'react';
import Modal from "../components/Modal";
import {Link} from 'react-router-dom'
import '../components/css/Dynasty.css'

function Subscribe() {

  return (
    <div className="subscribe">
      <div className="dyna-cont">
        <Container style={{ backgroundColor: 'transparent' }}>
          <AnimationTitles
            title={`Coming Soon: The Dynasty VOL 1`}
            className="title text-center mx-auto w-75"
          />
          <div style={{ border: 'none' }} className="dyna-image">
            <img className="dynasty-image" src={require('../images/dyna.png')} alt="dyna" />
          </div>
          <div className="learn-more-cont" style={{ border: 'none' }}>
            <Link to="/dyna"><button  style={{padding:'5px'}}className="learn-more">More Info</button></Link>
          
             
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Subscribe;
