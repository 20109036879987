import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/logo/logo.png";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import "./navbar.css";

function NavBar() {
  return (
    <Navbar style={{}}expand="lg" className="py-3 background">
      <Container>
        <Navbar.Brand className="me-lg-5">
          <img className="dsq" src={require('../../images/dsqlogo.jpeg')} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
        
          <ul className="p-0 list" style={{textDecoration:'none', listStyleType:'none'}}>

                <li className="x-logo"><a style={{color:'white'}}href="https://x.com/DropSquadProd"><XIcon/>
               </a></li>
                <li className="x-logo"><a style={{color:'white'}}href="https://www.youtube.com/@DropSquadProductions"><YouTubeIcon/></a></li>
                <li className="x-logo"><a style={{color:'white'}}href="https://www.facebook.com/people/Drop-Squad-Productions/61560334190075/"><FacebookIcon/></a></li>
                <Link style={{textDecoration:'none',color:'white',fontSize:'1em'}}className="px-lg-3"to="/contact">Contact</Link>
              </ul>

          </Nav>
        </Navbar.Collapse>
        <div className="d-flex align-items-center order">
          <span className="line d-lg-inline-block d-none"></span>
    
        </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;
