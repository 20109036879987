import { Button, Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CountDown from "../components/functions/CountDown";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";

const DropSquadHeader = () =>{

  const logo = require('../images/dsqlogo.jpeg')
  function like(e) {
    return e.target.classList.value === "fa-regular fa-heart like"
      ? (e.target.classList.value = "fa-solid fa-heart like text-danger")
      : (e.target.classList.value = "fa-regular fa-heart like");
  }

  return (
    <div className="loading position-relative">
      <Container className="d-flex justify-content-between align-items-center gap-md-5 flex-column flex-md-row mt-3 mt-xl-4 overflow-hidden">
        <motion.div
          initial={{ x: -300 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.2 }}
        >
          <AnimationTitles title="Drop Squad Productions" />
          <p style={{fontSize:'2em', color:'gold'}}>
         We blend passion, culture, and innovation to redefine the pulse of hip-hop production. 
          </p>
      
        </motion.div>
        <motion.div
          initial={{ x: 400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-100 my-5"
        >
          <div style={{border:'1px solid transparent', boxShadow:'10px 10px 10px gold'}}>
            <Card className="bg-black-100 rounded">
           
                <div className="rounded overflow-hidden position-relative ">
                  <img
                    alt="img"
                    src={'https://res.cloudinary.com/ducg1tnoi/image/upload/v1717246110/dsq_qf5hqk.jpg'}
                    style={{width: '100%', height: '100%', objectFit: 'cover'}} 
                  />

                </div>

            </Card>
           
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          style={{ color: "white" }}
          className="d-md-none d-flex justify-content-between align-items-center my-4 features"
        >
  
        </motion.div>
      </Container>
    </div>
  );
}

export default DropSquadHeader;
