import { BrowserRouter } from "react-router-dom";
import DynastySection from "./pages/DynastySection";
import NavBar from "./components/navbar/Navbar";
import Footer from "./pages/Footer";
import AiMP from "./pages/AiMP";
import DropSquadHeader from "./pages/DropSquadHeader";
import AllArtists from "./pages/AllArtists";
import { BrowserRouter as Router, Link, Route, Routes, useLocation} from 'react-router-dom';
import Mo3 from "./pages/Mo3";
import ScrollToTop from './components/ScrollToTop';
import Dynasty from "./pages/Dynasty";
import Contact from "./pages/Contact";
import ArtistPage from "./pages/ArtistPage";
const AppContent = () => {
  const location = useLocation();
  const hiddenPaths = ['/mo3', '/dyna', '/contact'];
  const artistPathPattern = /^\/artists\/\d+$/; 
  const shouldHideCommonComponents = hiddenPaths.includes(location.pathname) || artistPathPattern.test(location.pathname);

  return (
    <>

      <Routes> 
      <Route path={'/contact'} element={<Contact/>}/>
        {/* <Route path={'/mo3'}element={<Mo3/>}/>
        <Route path={'/dyna'} element={<Dynasty/>}/>

        <Route path={'/artists/:id'} element={<ArtistPage/>}/>
   
      */}
      </Routes>
      {!shouldHideCommonComponents && (
        <>
        <NavBar />
        <DropSquadHeader/>
        {/* <AiMP />
        <DynastySection />   
        <AllArtists /> 
      */}
       
        <Footer />
        </>
      )}

    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <AppContent />
    </Router>
  );
}

export default App;
